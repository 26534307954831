import React from 'react';

export function HeroHeader({ title, children }) {
	return (
		<div className="flex items-center justify-center header-pattern flex-col pt-12 pb-12 md:pt-24 md:pb-24">
			<h3 className="text-3xl font-bold relative z-0">
				<span className="custom-underline">{title}</span>
			</h3>
			{children && (
				<div className="text-center text-gray-700 text-sm pt-12 px-8">{children}</div>
			)}
		</div>
	);
}
