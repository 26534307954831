import { gql } from 'apollo-boost';

export const INSERT_STUDIES = gql`
	mutation createStudy($studies: [wanted_study_following_insert_input!]!) {
		__typename
		insert_wanted_study_following(objects: $studies) {
			returning {
				id
			}
		}
	}
`;
