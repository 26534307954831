import React from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';

export function FromStudy({ hidden, handleSubmit, name }) {
	return (
		<div className={classNames('flex', { hidden })}>
			<Formik
				initialValues={{ name: '', location: '', speciality: '' }}
				onSubmit={handleSubmit}
			>
				{({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit}>
						<div className="flex flex-wrap  mb-6 items-end">
							<div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
								<label
									className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									htmlFor={`name-${name}`}
								>
									Nom
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id={`name-${name}`}
									type="text"
									name="name"
									placeholder="Intitulé de formation"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.name}
								/>
							</div>
							<div className="w-full md:w-1/4 px-3 mb-6  md:mb-0">
								<label
									className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									htmlFor={`location-${name}`}
								>
									Lieu
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id={`location-${name}`}
									type="text"
									placeholder="Paris"
									name="location"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.location}
								/>
							</div>
							<div className="w-full md:w-1/4 px-3 mb-6  md:mb-0">
								<label
									className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									htmlFor={`speciality-${name}`}
								>
									Spécialité{' '}
									<span className="text-xs text-gray-500 ml-2">Optionnel</span>
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id={`speciality-${name}`}
									type="text"
									placeholder="Programmation"
									name="speciality"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.speciality}
								/>
							</div>
							<div className="w-full md:w-1/4 px-3 mb-6  md:mb-0">
								<button
									type="submit"
									className="bg-iutblack shadow-md hover:shadow-lg text-white font-bold text-lg px-8 py-2 rounded-md w-full h-full"
									disabled={isSubmitting}
								>
									Valider
								</button>
							</div>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
}
