import React, { useState, useEffect } from 'react';
import './css/tailwind-build.css';
import './App.css';
import logo from './assets/logoiut.webp';
import shortId from 'shortid';
import curry from 'curry';
import { useMutation } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';

import { Heading } from './components/Heading';
import { HeroHeader } from './components/HeroHeader';
import { EmptyState } from './components/EmptyState';
import { ValidationButton } from './components/ValidationButton';
import { NotificationSuccess, NotificationError } from './components/Notification';
import { FromStudy } from './components/FormStudy';
import { PoursuiteItem } from './components/PoursuiteItem';
import { Modal } from './components/Modal';

import { INSERT_STUDIES } from './queries/wantedStudy';

const constants = {
	ENGINEER: 'engineer',
	LICENCE: 'licence',
	LICENCE_PRO: 'licencepro',
	OTHER: 'other',
	PROFESSIONAL: 'professional',
	REMARKS: 'remarks'
};

function App() {
	const [studies, setStudies] = useState([]);
	const [name, setName] = useState('');
	const [firstName, setFirstName] = useState('');
	const [professional, setProfessional] = useState('');
	const [feedback, setFeedback] = useState('');
	const [hiddenEngineerForm, setHiddenEngineerForm] = useState(true);
	const [hiddenLicenceForm, setHiddenLicenceForm] = useState(true);
	const [hiddenLicenceProForm, setHiddenLicenceProForm] = useState(true);
	const [hiddenOtherForm, setHiddenOtherForm] = useState(true);
	const [hiddenNotif, setHiddenNotif] = useState(true);
	const [hiddenNotifError, setHiddenNotifError] = useState(true);
	const [hiddenNotifErrorStudy, setHiddenNotifErrorStudy] = useState(true);
	const [hiddenModal, setHiddenModal] = useState(true);

	const [addStudy, { data, loading, error }] = useMutation(INSERT_STUDIES, {
		onError: ({ graphQLErrors, networkError }) => {
			if (graphQLErrors) graphQLErrors.forEach(error => Sentry.captureMessage(error.message));
			if (networkError) Sentry.captureMessage(networkError.message);
		}
	});

	useEffect(() => {
		if (data && !loading && !error) {
			closeModal();
			setHiddenNotif(false);
			resetForm();
		}
	}, [data, loading, error]);

	function filterStudies(type) {
		return studies
			.filter(item => item.type === type)
			.map(item => {
				return (
					<PoursuiteItem
						key={item.id}
						name={item.name}
						place={item.location}
						speciality={item.speciality}
						handleClick={() => deleteItem({ id: item.id })}
					/>
				);
			});
	}

	function filterStudiesRaw(type) {
		return studies.filter(item => item.type === type);
	}

	function submitPoursuite(type, values, { setSubmitting, resetForm }) {
		if (!values.name || !values.location) {
			setSubmitting(false);
			setHiddenNotifErrorStudy(false);
			return;
		}
		setStudies([
			...studies,
			Object.assign(values, {
				type,
				id: shortId.generate()
			})
		]);
		resetForm({});
		setSubmitting(false);
	}

	function deleteItem({ id }) {
		setStudies(studies.filter(item => item.id !== id));
	}

	function closeAllForms() {
		setHiddenLicenceForm(true);
		setHiddenEngineerForm(true);
		setHiddenLicenceProForm(true);
		setHiddenOtherForm(true);
	}

	function handleValidation() {
		if (name && firstName && (professional || feedback || studies.length > 0)) {
			setHiddenModal(false);
			return;
		}

		setHiddenNotifError(false);
	}

	function resetForm() {
		setName('');
		setFirstName('');
		setProfessional('');
		setFeedback('');
		setStudies([]);
		closeAllForms();
	}

	function closeNotif() {
		setHiddenNotif(true);
	}

	function closeNotifError() {
		setHiddenNotifError(true);
	}
	function closeNotifErrorStudy() {
		setHiddenNotifErrorStudy(true);
	}

	function closeModal() {
		setHiddenModal(true);
	}

	function handleValidate() {
		let studiesMutation = [];
		if (studies.length > 0) {
			const studiesMutated = studies.map(item => {
				const object = {
					location: item.location,
					name: item.name,
					speciality: item.speciality,
					student_name: firstName.toUpperCase(),
					type: item.type,
					promotion_year: process.env.REACT_APP_PROMOTION,
					student_lastname: name.toUpperCase(),
					department: process.env.REACT_APP_DEPARTMENT
				};
				return object;
			});
			studiesMutation = studiesMutation.concat(studiesMutated);
		}
		if (feedback) {
			const object = {
				remarks: feedback,
				student_name: firstName.toUpperCase(),
				type: constants.REMARKS,
				promotion_year: process.env.REACT_APP_PROMOTION,
				student_lastname: name.toUpperCase(),
				department: process.env.REACT_APP_DEPARTMENT
			};
			studiesMutation.push(object);
		}
		if (professional) {
			const object = {
				precision_pro_insert: professional,
				student_name: firstName.toUpperCase(),
				type: constants.PROFESSIONAL,
				promotion_year: process.env.REACT_APP_PROMOTION,
				student_lastname: name.toUpperCase(),
				department: process.env.REACT_APP_DEPARTMENT
			};
			studiesMutation.push(object);
		}

		addStudy({
			variables: {
				studies: studiesMutation
			}
		});
	}

	function generateStudyValidationItem(type, title) {
		return (
			<>
				{filterStudiesRaw(type).length > 0 && (
					<div className="flex flex-row justify-around flex-wrap my-6">
						<div className="flex flex-col justify-center ml-4 text-center w-full">
							<h4 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-center">
								{title}
							</h4>
							{filterStudiesRaw(type).map(item => (
								<p className="text-xs" key={item.id}>
									<span className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
										Nom:
									</span>{' '}
									{item.name}
								</p>
							))}
						</div>
					</div>
				)}
			</>
		);
	}

	return (
		<div className="iut-pattern h-full w-full md:py-12 min-h-screen relative">
			<ValidationButton title="Valider vos réponses" handleClick={handleValidation} />
			<Modal
				hidden={hiddenModal}
				title="Vérifiez les informations"
				handleClose={closeModal}
				handleValidate={handleValidate}
				loading={loading}
				error={error}
			>
				<div className="max-height-70 md:py-8 overflow-y-scroll">
					<div className="flex flex-row justify-around flex-wrap my-6">
						<div className="flex flex-col justify-center ml-4">
							<h4 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-center">
								Prénom
							</h4>
							<p className="text-xs text-center">{firstName}</p>
						</div>
						<div className="flex flex-col justify-center ml-4 text-center">
							<h4 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-center">
								Nom
							</h4>
							<p className="text-xs">{name}</p>
						</div>
					</div>
					{generateStudyValidationItem(constants.ENGINEER, "Écoles d'ingénieur")}
					{generateStudyValidationItem(
						constants.LICENCE,
						'Bac +3 : Licence Universitaire L3'
					)}
					{generateStudyValidationItem(
						constants.LICENCE_PRO,
						'Bac +3 : Licence Professionnelle'
					)}
					{generateStudyValidationItem(
						constants.OTHER,
						'Autres formations ou écoles privées'
					)}
					{professional && (
						<div className="flex flex-row justify-around flex-wrap my-6">
							<div className="flex flex-col justify-center ml-4 text-center w-full">
								<h4 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-center">
									Projet professionnel
								</h4>
								<p className="text-xs">{professional}</p>
							</div>
						</div>
					)}
					{feedback && (
						<div className="flex flex-row justify-around flex-wrap my-6">
							<div className="flex flex-col justify-center ml-4 text-center w-full">
								<h4 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-center">
									Remarques divers
								</h4>
								<p className="text-xs">{feedback}</p>
							</div>
						</div>
					)}
				</div>
			</Modal>
			<NotificationSuccess
				pill="Succès"
				message="Vos choix ont bien été enregistrés"
				hidden={hiddenNotif}
				closeNotif={closeNotif}
				handleClick={closeNotif}
			/>
			<NotificationError
				pill="Erreur"
				message="Merci de remplir au minimum les champs Nom, Prénom et une des sections en dessous"
				hidden={hiddenNotifError}
				closeNotif={closeNotifError}
				handleClick={closeNotifError}
			/>
			<NotificationError
				pill="Erreur"
				message="Remplissez au minimum nom et lieu"
				hidden={hiddenNotifErrorStudy}
				closeNotif={closeNotifErrorStudy}
				handleClick={closeNotifErrorStudy}
			/>

			<div className="container mx-auto bg-white shadow-none md:shadow-xl rounded-lg">
				<div className="flex justify-center items-center py-12 px-8">
					<div className="flex justify-center items-center flex-col">
						<h1 className="text-2xl font-semibold text-center">
							Questionnaire poursuite d'études
						</h1>
						<h2 className="text-2xl font-semibold text-iutyellow text-center">
							Promotion {process.env.REACT_APP_PROMOTION}
						</h2>
					</div>
					<div className="w-64 ml-12">
						<img src={logo} alt="logo iut informatique graphique" />
					</div>
				</div>
				<div className="py-8">
					<form className="w-full max-w-lg mx-auto">
						<div className="flex flex-wrap md:-mx-3 mb-6">
							<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
								<label
									className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									htmlFor="grid-first-name"
								>
									Prénom
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="grid-first-name"
									type="text"
									placeholder="Jane"
									onChange={e => {
										setFirstName(e.target.value);
									}}
									value={firstName}
								/>
							</div>
							<div className="w-full md:w-1/2 px-3">
								<label
									className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									htmlFor="grid-last-name"
								>
									Nom
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="grid-last-name"
									type="text"
									placeholder="Doe"
									onChange={e => {
										setName(e.target.value);
									}}
									value={name}
								/>
							</div>
						</div>
					</form>
				</div>

				<div className="flex flex-col">
					<HeroHeader title="Poursuite d'études" />

					<div className="pb-12 md:pb-24  pt-6 md:pt-12">
						<div className="py-8">
							<Heading
								title="École d'ingénieur"
								handleClick={() => {
									closeAllForms();
									setHiddenEngineerForm(!hiddenEngineerForm);
								}}
							/>
							<div className="w-2/3 mx-auto flex justify-center items-center content-center flex-col">
								<FromStudy
									hidden={hiddenEngineerForm}
									handleSubmit={curry(submitPoursuite)(constants.ENGINEER)}
									name="engineer"
								/>
								{filterStudies(constants.ENGINEER).length === 0 ? (
									<EmptyState />
								) : (
									filterStudies(constants.ENGINEER)
								)}
							</div>
						</div>

						<div className="py-8">
							<Heading
								title="Bac +3 : Licence Universitaire L3"
								handleClick={() => {
									closeAllForms();
									setHiddenLicenceForm(!hiddenLicenceForm);
								}}
							/>
							<div className="w-2/3 mx-auto flex justify-center items-center content-center flex-col">
								<FromStudy
									hidden={hiddenLicenceForm}
									handleSubmit={curry(submitPoursuite)(constants.LICENCE)}
									name="licence"
								/>
								{filterStudies(constants.LICENCE).length === 0 ? (
									<EmptyState />
								) : (
									filterStudies(constants.LICENCE)
								)}
							</div>
						</div>
						<div className="py-8">
							<Heading
								title="Bac +3 : Licence Professionnelle"
								handleClick={() => {
									closeAllForms();
									setHiddenLicenceProForm(!hiddenLicenceProForm);
								}}
							/>
							<div className="w-2/3 mx-auto flex justify-center items-center content-center flex-col">
								<FromStudy
									hidden={hiddenLicenceProForm}
									handleSubmit={curry(submitPoursuite)(constants.LICENCE_PRO)}
									name="licencepro"
								/>
								{filterStudies(constants.LICENCE_PRO).length === 0 ? (
									<EmptyState />
								) : (
									filterStudies(constants.LICENCE_PRO)
								)}
							</div>
						</div>
						<div className="py-8">
							<Heading
								title="Autres formations ou écoles privées"
								handleClick={() => {
									closeAllForms();
									setHiddenOtherForm(!hiddenOtherForm);
								}}
							/>
							<div className="w-2/3 mx-auto flex justify-center items-center content-center flex-col">
								<FromStudy
									hidden={hiddenOtherForm}
									handleSubmit={curry(submitPoursuite)(constants.OTHER)}
									name="other"
								/>
								{filterStudies(constants.OTHER).length === 0 ? (
									<EmptyState />
								) : (
									filterStudies(constants.OTHER)
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col">
					<HeroHeader title="Projet professionnel">
						<p>
							Vous n'avez pas de poursuite d'étude mais pensez vous lancer dans la vie
							active ?
							<br />
							Laissez nous savoir ce que vous souhaitez faire
						</p>
					</HeroHeader>

					<div className=" py-12 md:py-24 px-8">
						<form className="w-full max-w-lg mx-auto">
							<div className="flex flex-wrap -mx-3 mb-6">
								<div className="w-full">
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
										htmlFor="professional-project"
									>
										Décrivez votre projet
									</label>
									<textarea
										className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
										id="professional-project"
										type="text"
										rows="7"
										placeholder="Je souhaite travailler en tant que..."
										onChange={e => {
											setProfessional(e.target.value);
										}}
										value={professional}
									></textarea>
								</div>
							</div>
						</form>
					</div>
				</div>

				<div className="flex flex-col">
					<HeroHeader title="Remarques diverses">
						<p>Une remarque / commentaire sur les formations ou sur un autre sujet ?</p>
					</HeroHeader>

					<div className="py-12 md:py-24 px-8">
						<form className="w-full max-w-lg mx-auto">
							<div className="flex flex-wrap -mx-3 mb-6">
								<div className="w-full">
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
										htmlFor="feedback"
									>
										Vos remarques
									</label>
									<textarea
										className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
										id="feedback"
										type="text"
										rows="7"
										placeholder="..."
										onChange={e => {
											setFeedback(e.target.value);
										}}
										value={feedback}
									></textarea>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div className="md:hidden">
					<button
						className="w-full bg-iutyellow active:bg-iutorange shadow-md hover:shadow-lg text-white font-bold text-lg px-8 py-8"
						onClick={handleValidation}
					>
						{' '}
						Valider vos réponses
					</button>
				</div>
			</div>
		</div>
	);
}

export default App;
