import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

const client = new ApolloClient({
	uri: `${process.env.REACT_APP_HASURA_HOST}`,
	request: operation => {
		operation.setContext({
			headers: {
				'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN
			}
		});
	}
});

ReactDOM.render(
	<ApolloProvider client={client}>
		<App />
	</ApolloProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
