import React from 'react';
import bin from '../assets/bin.svg';

export function PoursuiteItem({ handleClick, name, place, speciality }) {
	return (
		<div className="shadow-md hover:shadow-lg py-6 px-6 w-full mt-6">
			<div className="flex items-center justify-between">
				<div className="flex flex-col">
					<div className="flex-row justify-start flex-wrap hidden md:flex">
						<div className="flex flex-col justify-center ml-4">
							<h4 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
								Nom
							</h4>
							<p className="text-xs">{name}</p>
						</div>
						<div className="flex flex-col justify-center ml-4">
							<h4 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
								Lieu
							</h4>
							<p className="text-xs">{place}</p>
						</div>
						{speciality && (
							<div className="flex flex-col justify-center ml-4">
								<h4 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
									Spécialité
								</h4>
								<p className="text-xs">{speciality}</p>
							</div>
						)}
					</div>
					<div className="flex flex-col justify-center block md:hidden">
						<h4 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
							Nom
						</h4>
						<p className="text-xs">{name}</p>
					</div>
					<div className="flex flex-col justify-center mt-4 block md:hidden">
						<h4 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
							Lieu
						</h4>
						<p className="text-xs">{place}</p>
					</div>
					{speciality && (
						<div className="flex flex-col justify-center mt-4 block md:hidden">
							<h4 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
								Spécialité
							</h4>
							<p className="text-xs">{speciality}</p>
						</div>
					)}
				</div>
				<button
					className="p-4 active:bg-iutorange hover:bg-iutyellow rounded-full"
					onClick={handleClick}
				>
					<img className="w-6" src={bin} alt="Icone suppression element" />
				</button>
			</div>
		</div>
	);
}
