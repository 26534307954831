import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Spinner } from './/Spinner/Spinner';

export function Modal({ children, title, handleClose, handleValidate, hidden, loading, error }) {
	useEffect(() => {
		document.addEventListener('keydown', checkKey, false);

		function checkKey(event) {
			if (event.keyCode !== 27) return;

			handleClose();
		}

		return () => {
			document.removeEventListener('keydown', checkKey, false);
		};
	}, [handleClose]);

	return (
		<div
			className={classNames(
				'fixed w-full h-full top-0 left-0 flex items-center justify-center z-20',
				{
					hidden
				}
			)}
		>
			<div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

			<div className="bg-white w-11/12 md:max-w-2xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
				<div
					className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
					onClick={handleClose}
				>
					<svg
						className="fill-current text-white"
						xmlns="http://www.w3.org/2000/svg"
						width="18"
						height="18"
						viewBox="0 0 18 18"
					>
						<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
					</svg>
					<span className="text-sm hidden md:block">(Esc)</span>
				</div>
				<div className="modal-content py-4 text-left px-6">
					<div className="flex justify-between items-center pb-3">
						<p className="text-2xl font-bold">{title}</p>
						<div className="modal-close cursor-pointer z-50" onClick={handleClose}>
							<svg
								className="fill-current text-black"
								xmlns="http://www.w3.org/2000/svg"
								width="18"
								height="18"
								viewBox="0 0 18 18"
							>
								<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
							</svg>
						</div>
					</div>

					<div>{children}</div>

					<div className="flex justify-end pt-2 items-center">
						{error && (
							<p className="text-red-500 text-xs">
								Une erreur est survenue, merci de réessayer
							</p>
						)}
						<button
							className="px-4 bg-transparent p-3 rounded-lg text-iutyellow hover:bg-gray-100 hover:text-iutorange mr-2"
							onClick={handleClose}
						>
							Annuler
						</button>
						<button
							className="modal-close px-4 bg-iutyellow p-3 rounded-lg text-white hover:bg-iutorange "
							onClick={handleValidate}
						>
							Valider
						</button>
						{loading && (
							<div className="ml-4">
								{' '}
								<Spinner />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
