import React from 'react';

export function Heading({ title, handleClick }) {
	return (
		<div className="flex items-center py-8 justify-center">
			<h3 className="text-xl font-semibold w-1/2 ">{title}</h3>
			<button
				className="bg-iutyellow active:bg-iutorange shadow-md hover:shadow-lg text-white font-bold text-lg rounded-br-full rounded-tl-full px-8 py-2"
				onClick={handleClick}
			>
				Ajouter +
			</button>
		</div>
	);
}
