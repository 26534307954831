import React from 'react';

export function ValidationButton({ handleClick, title }) {
	return (
		<div className="fixed right-0 bottom-0 pb-6 z-20 hidden md:block">
			<button
				className="bg-iutorange py-2 pr-4 pl-8 rounded-l-lg shadow-xl text-white text-2xl hover:shadow-2xl active:bg-iutyellow"
				onClick={handleClick}
			>
				{title}
			</button>
		</div>
	);
}
