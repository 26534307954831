import React, { useEffect } from 'react';
import classNames from 'classnames';

export function NotificationSuccess(args) {
	return <Notification {...args} color="indigo" colorPill="green" />;
}

export function NotificationError(args) {
	return <Notification {...args} color="red" colorPill="red" />;
}

function Notification({
	pill,
	message,
	hidden = true,
	time = 3000,
	closeNotif,
	handleClick,
	color,
	colorPill
}) {
	useEffect(() => {
		if (hidden) return;

		setTimeout(() => {
			closeNotif();
		}, time);
	}, [hidden, closeNotif, time]);

	return (
		<div
			className={classNames(
				'fixed flex text-center py-4 lg:px-4 bottom-0 right-0 w-full justify-center z-10',
				{ hidden }
			)}
			onClick={handleClick}
		>
			<div
				className={`p-2 bg-${color}-800 items-center text-white leading-none lg:rounded-full flex lg:inline-flex`}
				role="alert"
			>
				{pill && (
					<span
						className={`flex rounded-full bg-${colorPill}-500 uppercase px-2 py-1 text-xs font-bold mr-3`}
					>
						{pill}
					</span>
				)}
				<span className="font-semibold mr-2 text-left flex-auto">{message}</span>
			</div>
		</div>
	);
}
